import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from "vue-meta";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor);

Vue.config.productionTip = false;

Vue.prototype.$env = {
  siteTitle: "Salex Admin System",
  siteUrl: "https://admin.salex.md/",
  shareImg: "/img/share.jpg",
  api: "https://admin.salex.md/api",
  imagesURL: "https://salex-images-live.s3.eu-central-1.amazonaws.com/",
  mainUrl: "https://salex.md/",
  bannersUrl: "https://admin.salex.md/banners/",
  mediaUrl: "https://salex.md/media/",
};

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});
Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
